import {
  CampaignAccountingType,
  CampaignBasicInfo,
  CampaignCodeType,
  AllowedTimeOfDay,
  AllowedWeekdays,
  ClaimCodeType,
  DownloadCodeType,
} from './types';
import dayjs from 'dayjs';
import { GlobalState } from 'little-state-machine';
import { CampaignsForm } from '../../../global';
import { fetchCampaignCodesExcel, fetchClaimCodesCsv } from '../../apis/campaigns-service';

export const AutocompleteOff = {
  autoComplete: 'new-password',
  form: {
    autoComplete: 'off',
  },
};

export const defaultFormFreeDelivery = (state: GlobalState): GlobalState => {
  return {
    ...state,
    campaignsForm: { ...getDefaultCampaignObject(), disablePreordersOnCampaign: false },
  };
};

export const defaultFormBasicInfo = (state: GlobalState): GlobalState => {
  return {
    ...state,
    campaignsForm: { ...getDefaultCampaignObject() },
  };
};

export const defaultFormLocalMarketing = (
  state: GlobalState,
  campaignAccountingType: CampaignAccountingType
): GlobalState => {
  return {
    ...state,
    campaignsForm: {
      ...getDefaultCampaignObject(),
      campaignCodeType: CampaignCodeType.Personal,
      deliveryMethods: ['all_options'],
      campaignAccountingType: campaignAccountingType,
      publicUsageLimit: 1000,
      allowedTimeOfDay: AllowedTimeOfDay.AllDay,
      allowedWeekdays: AllowedWeekdays.AllDays,
    },
  };
};

export const updateCampaignsForm = (state: GlobalState, payload: CampaignsForm): GlobalState => {
  return {
    ...state,
    campaignsForm: {
      ...state.campaignsForm,
      ...payload,
    },
  };
};

export const defaultFormStateClaimCodes = (state: GlobalState): GlobalState => {
  return {
    ...state,
    campaignsForm: {
      start: dayjs().startOf('day').format(DateTimePickerFormat),
      end: dayjs().endOf('day').format(DateTimePickerFormat),
      claimCodesConfigs: Object.values(ClaimCodeType)
        .filter((x) => !isNaN(Number(x)))
        .map((type) => {
          return {
            claimCodeType: type,
            amountOfCodes: 0,
          };
        }),
    },
  };
};

export const defaultFormProductCoupon = (
  state: GlobalState,
  campaignAccountingType: CampaignAccountingType
): GlobalState => {
  return {
    ...state,
    campaignsForm: {
      ...getDefaultCampaignObject(),
      campaignAccountingType: campaignAccountingType,
      requireLogIn: true,
    },
  };
};

export const getDefaultCampaignObject = (): CampaignBasicInfo => {
  const startDate = dayjs().startOf('day');
  const endDate = dayjs().endOf('day');
  return {
    combinable: true,
    applyDiscountOnDelivery: false,
    name: '',
    requireLogIn: false,
    start: startDate.toISOString(),
    end: endDate.toISOString(),
    description: '',
    added: dayjs().toISOString(),
    allowedTimeOfDay: AllowedTimeOfDay.AllDay,
    allowedWeekdays: AllowedWeekdays.AllDays,
    campaignTypeForAnalytics: undefined,
  };
};

export const downloadCodeExcel = async (
  campaignId: string,
  campaignName: string,
  downloadCodeType: DownloadCodeType = DownloadCodeType.All
): Promise<void> => {
  const { data, error } = await fetchCampaignCodesExcel(campaignId, downloadCodeType);
  if (error || !data) {
    return Promise.reject(error);
  }
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${campaignName}.xls`);

  // Append to html link element page
  document.body.appendChild(link);

  // Start download
  link.click();

  // Clean up and remove the link
  link.parentNode?.removeChild(link);
  return Promise.resolve();
};

export const downloadClaimCodesCsv = async (campaignIds: string[]): Promise<void> => {
  const data = await fetchClaimCodesCsv(campaignIds);
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'claim-codes.csv');

  document.body.appendChild(link);

  link.click();

  link.parentNode?.removeChild(link);
  return Promise.resolve();
};

export const DateTimePickerFormat = 'YYYY-MM-DDTHH:mm';
export const DatePickerFormat = 'YYYY-MM-DD';

export enum CampaignTypes {
  B2BPrepaidCodes = 'b2bprepaidcodes',
  RestaurantClaimCodes = 'restaurantsclaimcodes',
  LocalMarketingCampaign = 'localmarketingcampaign',
  ShopSpecificCampaign = 'shopspecificcampaign',
  LoyaltyPrize = 'loyaltyprize',
  LoyaltyDiscount = 'loyaltydiscount',
  Combo = 'combo',
  FreeDelivery = 'freedelivery',
  ProductCoupon = 'productcoupon',
}

export const PerfettaProduct = 1102;
