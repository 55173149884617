import { Add, Search } from '@mui/icons-material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { Grid, IconButton, Typography } from '@mui/material';
import styled from 'styled-components';
import { useAllDosings, useAllIngredients } from '../../../apis/products-api';
import { IngredientDto } from '@kotipizzagroup/kotipizza-products-api-client';

type ProductIngredientSearchProps = {
  onIngredientSelect?: (ingredientId: number) => void;
};

export const ProductIngredientSearch = (props: ProductIngredientSearchProps) => {
  const { onIngredientSelect } = props;
  const { data: ingredients, isLoading } = useAllIngredients();
  const { data: dosingDetails, isLoading: isLoadingDosingDetails } = useAllDosings();

  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (_: any, newInputValue: string) => {
    setInputValue(newInputValue);
  };

  const handleChange = (option: any) => {
    const ingredient = option as IngredientDto;
    onIngredientSelect && onIngredientSelect(ingredient.ingredientId || 0);
  };

  const OPTIONS_LIMIT = 10;
  const defaultFilterOptions = createFilterOptions();
  const highlightOptions = {
    insideWords: true,
  };

  const filterOptions = (options: any, state: any) => {
    return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
  };

  const getIngredientCategoryName = (ingredientCategoryId: number): string => {
    return (
      dosingDetails?.ingredientCategories?.find((x) => x.ingredientCategoryId === ingredientCategoryId)?.name || ''
    );
  };

  return (
    <Autocomplete
      freeSolo
      disableClearable
      autoHighlight
      blurOnSelect
      openOnFocus={false}
      loading={isLoading || isLoadingDosingDetails}
      inputValue={inputValue}
      onChange={(_, value) => handleChange(value)}
      onInputChange={handleInputChange}
      options={ingredients || []}
      filterOptions={filterOptions}
      getOptionLabel={(option) => (option as IngredientDto).name || ''}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            type: 'search',
            endAdornment: <Search />,
          }}
          label="Etsi lisättävä raaka-aine"
        />
      )}
      renderOption={(props, option, { inputValue }) => {
        const ingredient = option as IngredientDto;
        const matches = match(ingredient.name || '', inputValue, highlightOptions);
        const parts = parse(ingredient.name || '', matches);

        return (
          <IngredientListItem active={!!ingredient.isActive} {...props} key={ingredient.ingredientId}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Grid container item xs={11} direction="column">
                <Grid item xs={11}>
                  {parts.map((part, index) => (
                    <span
                      key={index}
                      style={{
                        fontWeight: part.highlight ? 700 : 400,
                      }}
                    >
                      {part.text}
                    </span>
                  ))}
                </Grid>
                <Grid item xs={11}>
                  <Typography variant="body2" textTransform="uppercase">
                    {getIngredientCategoryName(ingredient.ingredientCategoryId || 0)}
                  </Typography>
                </Grid>
              </Grid>

              <Grid item xs={1}>
                <IconButton color="primary">
                  <Add />
                </IconButton>
              </Grid>
            </Grid>
          </IngredientListItem>
        );
      }}
    />
  );
};

const IngredientListItem = styled.li<{ active: boolean }>`
  background: ${(props) => (props.active ? 'inherit' : props.theme.palette.error.light)};
`;
