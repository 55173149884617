import { useTranslation } from 'react-i18next';
import { Button, Grid, Paper, Typography, Link, Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { useMemo } from 'react';
import { HasAllPermissions, PRODUCTS_WRITE } from '../../shared/utils/permissions';
import { useUserPermissions } from '../../apis/user-details';
import styled from 'styled-components';
import { Route, Routes, useNavigate } from 'react-router-dom';
import StyledDataGrid from '../../shared/DataGrid/StyledDataGrid';
import { useAllOutOfStockProducts, useAllProducts } from '../../apis/products-api';
import { OutOfStockProductsDetails } from './OutOfStockProductDetails';
import { OutOfStockProductDto } from '@kotipizzagroup/kotipizza-products-api-client';
import { useAllRestaurants } from '../../apis/shops-api';
import dayjs from 'dayjs';

interface OutOfProcuctsList {
  [key: number]: OutOfStockProductRow[];
}
interface ListData {
  productId: number;
  productName?: string;
  outOfStocks: OutOfStockProductRow[];
}
interface OutOfStockProductRow extends OutOfStockProductDto {
  restaurantName?: string;
}

export const OutOfStockProductsPage = (): JSX.Element => {
  const { t } = useTranslation();
  const { roles: userPermissions } = useUserPermissions();
  const navigate = useNavigate();
  const { data: outOfStockProducts, isLoading: isOutOfStockProductsLoading } = useAllOutOfStockProducts();
  const { data: products, isLoading: isProductsLoading } = useAllProducts();
  const { data: restaurants, isLoading: isRestaurantsLoading } = useAllRestaurants();

  const isLoading = useMemo(
    () => isOutOfStockProductsLoading || isProductsLoading || isRestaurantsLoading,
    [isOutOfStockProductsLoading, isProductsLoading, isRestaurantsLoading]
  );

  const list = useMemo((): ListData[] => {
    if (!outOfStockProducts || isLoading) return [];

    const outOfStocksByProductId: OutOfProcuctsList = outOfStockProducts.reduce((a, product) => {
      if (product.productId) {
        if (!a[product.productId]) {
          a[product.productId] = [];
        }
        a[product.productId].push({
          ...product,
          restaurantName: restaurants?.find((restaurant) => restaurant.shopExternalId === product.externalShopId)
            ?.displayName,
        });
      }
      return a;
    }, {} as OutOfProcuctsList);

    return Object.keys(outOfStocksByProductId).map((key) => ({
      productId: Number(key),
      productName: products?.find((p) => p.productId === Number(key))?.name,
      outOfStocks: outOfStocksByProductId[Number(key)],
    }));
  }, [isLoading, outOfStockProducts, products, restaurants]);

  const handleNewOutOfStockProduct = () => {
    navigate('/out-of-stock-products/new');
  };

  const columns: GridColDef[] = [
    {
      field: 'productId',
      headerName: t('outOfStockProducts.list.outOfStockProduct'),
      flex: 1,
      renderCell: (params) => {
        if (!params.row.productName) return null;
        return (
          <StyledLink onClick={() => navigate('/out-of-stock-products/' + params.row.productId)}>
            {params.row.productName}
          </StyledLink>
        );
      },
    },
    {
      field: 'externalShopId',
      headerName: t('outOfStockProducts.list.outOfStockFromRestaurant'),
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            {params.row.outOfStocks.map((outOfStocks: OutOfStockProductRow) => {
              if (!outOfStocks.restaurantName) return null;
              return <p key={outOfStocks.restaurantName}>{outOfStocks.restaurantName}</p>;
            })}
          </div>
        );
      },
    },
    {
      field: 'added',
      headerName: t('outOfStockProducts.list.outOfStockTime'),
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            {params.row.outOfStocks.map((o: OutOfStockProductDto) => {
              return (
                <p key={o.added}>
                  {t('outOfStockProducts.list.outOfStock')} {dayjs(o.added).format('D.M.YYYY klo HH:mm')}
                </p>
              );
            })}
          </div>
        );
      },
    },
    {
      field: 'edit',
      headerName: t('outOfStockProducts.list.actions'),
      renderCell: (params) => {
        return (
          <div>
            <StyledLink
              key={params.row.productId + '-edit'}
              onClick={() => navigate('/out-of-stock-products/' + params.row.productId)}
            >
              {t('outOfStockProducts.list.edit')}
            </StyledLink>
          </div>
        );
      },
    },
  ];

  return (
    <Routes>
      <Route path=":id" element={<OutOfStockProductsDetails />} />
      <Route
        index
        element={
          <>
            <TopBar>
              <Typography variant="h1">{t('outOfStockProducts.list.title')}</Typography>
              <Button
                disabled={!HasAllPermissions(userPermissions || [], [PRODUCTS_WRITE])}
                variant="contained"
                onClick={handleNewOutOfStockProduct}
                color="primary"
              >
                {t('product.addOutOfStockProduct')}
              </Button>
            </TopBar>

            <DataGridContainer display="flex">
              <StyledDataGrid
                disableRowSelectionOnClick
                rows={list || []}
                columns={columns}
                getRowId={(row) => row.productId}
                loading={isLoading}
                getRowClassName={() => ''}
              />
            </DataGridContainer>
            <StyledToolbar variant="outlined">
              <Grid display="flex" container spacing={3}>
                <Grid item xs={12} sm={6}></Grid>
              </Grid>
            </StyledToolbar>
          </>
        }
      />
    </Routes>
  );
};

export const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 15px 0;
`;

const StyledLink = styled(Link)`
  font-size: 15px;
`;

const StyledToolbar = styled(Paper)`
  margin-bottom: 1em;
  display: flex;
  padding: 1em;
`;

const DataGridContainer = styled(Box)`
  height: calc(100vh - 300px);
`;

export default OutOfStockProductsPage;
