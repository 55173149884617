import { TextField } from '@mui/material';
import { Controller, FieldError, FieldValues, UseControllerProps } from 'react-hook-form';

interface CampaignDateTimePickerProps<T extends FieldValues> extends UseControllerProps<T> {
  error?: FieldError | undefined;
  label: string;
  isDirty?: boolean;
  disabled?: boolean;
  dateOnly?: boolean;
}

export const DateTimePicker = <T extends FieldValues>({
  name,
  defaultValue,
  label,
  control,
  error,
  isDirty,
  disabled,
  dateOnly,
  ...rest
}: CampaignDateTimePickerProps<T>): JSX.Element => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <TextField
          {...rest}
          disabled={disabled}
          error={!!error && isDirty}
          helperText={error?.message}
          label={label}
          type={dateOnly ? 'date' : 'datetime-local'}
          variant="outlined"
          {...field}
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
    />
  );
};
