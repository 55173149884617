import { TextField, TextFieldProps } from '@mui/material';
import { Controller, FieldError, FieldValues, UseControllerProps } from 'react-hook-form';

interface CustomTextFieldInputProps<T extends FieldValues> extends UseControllerProps<T> {
  error?: FieldError | undefined;
  label: string;
  isDirty?: boolean;
  textFieldProps?: TextFieldProps;
  disabled?: boolean;
  readOnly?: boolean;
}

export const CustomTextFieldInput = <T extends FieldValues>({
  name,
  defaultValue,
  label,
  control,
  error,
  textFieldProps,
  disabled,
  readOnly = false,
  ...rest
}: CustomTextFieldInputProps<T>): JSX.Element => {
  return (
    <Controller
      {...rest}
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value, ref } }) => (
        <TextField
          inputRef={ref}
          InputLabelProps={{ shrink: !!value || value == 0 }}
          error={!!error}
          helperText={error?.message}
          label={label}
          {...textFieldProps}
          variant="outlined"
          value={value ?? ''}
          onChange={(e) => (textFieldProps?.type === 'number' ? onChange(+e.target.value) : onChange(e.target.value))}
          disabled={disabled ? true : false}
          InputProps={{
            readOnly,
          }}
        />
      )}
    />
  );
};
