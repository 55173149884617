import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { authProvider } from '../authProvider';
import LoadingIndicator from './shared/LoadingIndicator';
import { BrowserRouter as Router } from 'react-router-dom';
import AppRoutes from '../AppRoutes';
import { useUserPermissions } from './apis/user-details';
import { useTranslation } from 'react-i18next';
import Main from './layout/Main';
import { Typography } from '@mui/material';
import { ConfirmContextProvider } from './shared/ConfirmDialog/ConfirmContext';
import { ConfirmDialog } from './shared/ConfirmDialog/ConfirmDialog';

const App: React.FC = () => {
  const { roles, isLoading } = useUserPermissions();
  const { t, ready } = useTranslation();

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authProvider.getLoginRequest()}
      loadingComponent={() => <LoadingIndicator message={t('global.loading')} />}
    >
      <ConfirmContextProvider>
        {isLoading || !ready ? (
          <LoadingIndicator message={t('global.loading')} />
        ) : !roles.length ? (
          <Main renderSideDrawer={false}>
            <>
              <Typography variant="h2">{t('permissions.notAuthorizedTitle')}</Typography>
              <Typography variant="body1">{t('permissions.notAuthrizedDetails')}</Typography>
            </>
          </Main>
        ) : (
          <Router>
            <AppRoutes />
          </Router>
        )}
        <ConfirmDialog />
      </ConfirmContextProvider>
    </MsalAuthenticationTemplate>
  );
};

export default App;
