import { Box, Button, Chip, Grid, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { LoadingButton } from '@mui/lab';
import { Save } from '@mui/icons-material';
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import {
  addOutOfStockProduct,
  removeOutOfStockProduct,
  useAllOutOfStockProducts,
  useAllProducts,
  useProduct,
} from '../../apis/products-api';
import { OutOfStockProductDto, ProductDto } from '@kotipizzagroup/kotipizza-products-api-client';
import LoadingOverlay from '../../shared/LoadingOverlay/LoadingOverlay';
import { SearchInput } from '../../shared/Search/SearchInput';
import { useAllRestaurants } from '../../apis/shops-api';
import StyledDataGrid from '../../shared/DataGrid/StyledDataGrid';
import { GridColDef } from '@mui/x-data-grid';
import { useCustomSnackBar } from '../../shared/SnackBar/useCustomSnackBar';
import { useQueryClient } from '@tanstack/react-query';

interface OutOfStockProductFormData {
  outOfStocks: OutOfStockProductDto[];
}

interface OutOfStockProductRow extends OutOfStockProductDto {
  restaurantName?: string;
}

type OutOfStockProductDetailsViewParams = {
  id?: string;
};

export const OutOfStockProductsDetails = () => {
  const { id } = useParams<OutOfStockProductDetailsViewParams>();
  const { t } = useTranslation();
  const [productId, setProductId] = useState(isNaN(Number(id)) ? 0 : Number(id));
  const isNewProduct = productId === 0;
  const [isSaving, setIsSaving] = useState(false);
  const snackBar = useCustomSnackBar();
  const queryClient = useQueryClient();

  const createNewOutOfStockProduct = (restaurantId: string): OutOfStockProductDto => ({
    productId: productId,
    externalShopId: restaurantId,
  });

  const { handleSubmit, control, reset } = useForm<OutOfStockProductFormData>({
    defaultValues: {
      outOfStocks: [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'outOfStocks',
  });

  const appendOutOfStockProduct = (product: OutOfStockProductDto) => {
    if (fields.find((f) => f.externalShopId === product.externalShopId)) {
      snackBar.showError('Restaurant already added');
      return;
    }
    append(product);
  };

  const productColumns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('outOfStockProductDetails.product.name'),
      flex: 1,
      renderCell: (params) => {
        return params.value;
      },
    },
    {
      field: 'productCategoryName',
      headerName: t('outOfStockProductDetails.product.productCategoryName'),
      flex: 1,
      renderCell: (params) => {
        return params.value;
      },
    },
    {
      field: 'active',
      headerName: t('outOfStockProductDetails.product.active'),
      flex: 1,
      renderCell: (params) => {
        return params.value ? (
          <Chip color="primary" label={t('shared.activeLabel.active')} />
        ) : (
          <Chip color="error" label={t('shared.activeLabel.inactive')} />
        );
      },
    },
  ];

  const restaurantColumns: GridColDef[] = [
    {
      field: 'externalShopId',
      headerName: 'Id',
      renderCell: (params) => {
        return params.value;
      },
    },
    {
      field: 'name',
      headerName: t('outOfStockProductDetails.product.name'),
      flex: 1,
      renderCell: (params) => {
        if (!params.row.restaurantName) return null;
        return params.row.restaurantName;
      },
    },
    {
      field: 'remove',
      headerName: t('outOfStockProductDetails.restaurant.remove'),
      width: 200,
      renderCell: (params) => {
        const index = fields.findIndex((f) => f.externalShopId === params.row.externalShopId);

        return (
          <Button color="error" variant="outlined" onClick={() => remove(index)}>
            {t('outOfStockProductDetails.restaurant.remove')}
          </Button>
        );
      },
    },
  ];

  const {
    data: product,
    isInitialLoading: isInitialLoadingProduct,
    isFetching: isFetchingProduct,
  } = useProduct(productId, { enabled: !isNewProduct });

  const { data: outOfStockProducts, isInitialLoading: isOutOfStockProductsInitialLoading } = useAllOutOfStockProducts();

  const outOfStocks = useMemo(() => {
    return outOfStockProducts?.filter((p) => p.productId === productId) || [];
  }, [outOfStockProducts, productId]);

  const { data: products, isInitialLoading: isInitialLoadingProducts } = useAllProducts();

  const { data: restaurants, isInitialLoading: isInitialLoadingRestaurants } = useAllRestaurants();

  const restaurantRows = useMemo((): OutOfStockProductRow[] => {
    return fields.map((f) => ({
      ...f,
      restaurantName: restaurants?.find((r) => r.shopExternalId === f.externalShopId)?.displayName,
    }));
  }, [fields, restaurants]);

  const isInitialLoadComplete =
    !isInitialLoadingProduct &&
    !isInitialLoadingProducts &&
    !isOutOfStockProductsInitialLoading &&
    !isInitialLoadingRestaurants;

  useEffect(() => {
    if (!isInitialLoadComplete) return;
    reset({ outOfStocks: outOfStockProducts?.filter((p) => p.productId == productId) });
  }, [isInitialLoadComplete, outOfStockProducts, productId, reset]);

  const isSaveDisabled = isNewProduct || isFetchingProduct;

  const handleProductSave: SubmitHandler<OutOfStockProductFormData> = async (formData) => {
    try {
      setIsSaving(true);
      for (const item of formData.outOfStocks) {
        // Find and post new items
        const itemExists = outOfStocks?.find((p) => p.externalShopId === item.externalShopId);
        if (!itemExists && item.productId && item.externalShopId) {
          await addOutOfStockProduct({ productId: item.productId, externalShopId: item.externalShopId });
        }
      }
      // Remove items
      for (const item of outOfStocks) {
        const shouldKeep = formData.outOfStocks.find((p) => p.externalShopId === item.externalShopId);
        if (!shouldKeep && item.productId && item.externalShopId) {
          await removeOutOfStockProduct({ productId: item.productId, externalShopId: item.externalShopId });
        }
      }
      setIsSaving(false);
      await queryClient.invalidateQueries(['out-of-stock-products']);
      snackBar.showSuccess('Out of stock details updated');
    } catch (e) {
      snackBar.showError('Could not update out of stock details');
      setIsSaving(false);
    }
  };

  const SaveButton = () => {
    return (
      <LoadingButton
        onClick={handleSubmit(handleProductSave)}
        startIcon={<Save />}
        loading={isSaving}
        disabled={isSaveDisabled}
        variant="contained"
        color="primary"
      >
        {t('global.save')}
      </LoadingButton>
    );
  };
  const handleAddRestaurant = (value: string) => {
    appendOutOfStockProduct(createNewOutOfStockProduct(value));
  };

  const handleSelectProduct = (value: string) => {
    setProductId(Number(value));
  };

  const productList: ProductDto[] = useMemo(() => {
    if (!product) return [];
    return [{ ...product }];
  }, [product]);

  return (
    <Box>
      {!isInitialLoadComplete ? <LoadingOverlay /> : null}
      <TopBar>
        <Typography variant="h1">
          {t('outOfStockProducts.list.title')} / {t('outOfStockProductDetails.markAsOutOfStock')}
        </Typography>
        <SaveButton />
      </TopBar>
      <Grid container item justifyContent="space-between" alignItems="center" my={2}>
        <Grid item xs={12} md={4}>
          <Typography variant="h2">{t('outOfStockProductDetails.product.title')}</Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          {isNewProduct && (
            <SearchInput
              values={products?.map((p) => ({ value: String(p.productId), label: p.name })) || []}
              noLabelPrefix={true}
              onSelect={handleSelectProduct}
              label={t('outOfStockProductDetails.product.search')}
            />
          )}
        </Grid>
      </Grid>
      {product && (
        <StyledDataGrid
          disableRowSelectionOnClick
          rows={productList}
          columns={productColumns}
          getRowId={(row) => row.productId}
          loading={false}
          hideFooter={true}
          getRowClassName={(params) => (params.row.active ? '' : '')}
        />
      )}

      {!isNewProduct && (
        <RestaurantsSection>
          <Grid container item justifyContent="space-between" alignItems="center" my={2} marginTop={6}>
            <Grid item xs={12} md={4}>
              <Typography variant="h2">{t('outOfStockProductDetails.restaurant.title')}</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <SearchInput
                values={restaurants?.map((r) => ({ value: r.shopExternalId, label: r.displayName })) || []}
                onSelect={handleAddRestaurant}
                noLabelPrefix={true}
                label={t('outOfStockProductDetails.restaurant.search')}
              />
            </Grid>
          </Grid>
          <StyledDataGrid
            disableRowSelectionOnClick
            rows={restaurantRows}
            columns={restaurantColumns}
            getRowId={(row) => row.externalShopId}
            loading={false}
            hideFooter={true}
            getRowClassName={(params) => (params.row.active ? '' : '')}
          />
        </RestaurantsSection>
      )}

      <Grid item xs={12} container marginTop={8}>
        <SaveButton />
      </Grid>
      <BottomBar />
    </Box>
  );
};

const BottomBar = styled.div`
  height: 100px;
  width: 100%;
`;

const RestaurantsSection = styled.div``;

const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
`;
