import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { CloseOutlined, Save } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useCustomSnackBar } from '../../shared/SnackBar/useCustomSnackBar';
import { DateTimePicker } from '../../shared/DateTimePicker/DateTimePicker';
import dayjs from 'dayjs';
import { ExceptionDate, deliveryPricing } from '.';
import { CustomTextFieldInput } from '../../shared/CustomTextFieldInput/CustomTextFieldInput';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { DatePickerFormat } from '../CampaignsPage/shared';
import { FormSwitch } from '../../shared/FormSwitch/FormSwitch';
import { upsertExceptionDate } from '../../apis/shops-api';

export type EditExceptionDateDialogProps = {
  open: boolean;
  onDialogClose: () => void;
  exceptionDate?: ExceptionDate;
};

const EditExceptionDateDialog: React.FC<EditExceptionDateDialogProps> = ({ open, onDialogClose, exceptionDate }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [holidays, setHolidays] = useState<ExceptionDate[]>([]);

  const { t } = useTranslation();
  const snackBar = useCustomSnackBar();
  const mutation = useMutation(upsertExceptionDate);
  const queryClient = useQueryClient();

  useEffect(() => {
    const fetchHolidays = async () => {
      const today = new Date();

      const response = await fetch(`https://date.nager.at/api/v3/PublicHolidays/${new Date().getFullYear()}/FI`);
      const allHolidays: ExceptionDate[] = await response.json();
      const midWeekHolidays = allHolidays.filter((holiday) => {
        const day = new Date(holiday.date).toLocaleString('fi-FI', { weekday: 'short' });
        return !['la', 'su'].includes(day);
      });

      const upComingHolidays = midWeekHolidays
        .filter((holiday) => new Date(holiday.date) >= today)
        .map((d) => ({
          ...d,
          name: d.localName || d.name,
        }));

      setHolidays(upComingHolidays);
      setIsLoading(false);
    };

    fetchHolidays();
  }, []);

  const {
    reset,
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isDirty },
  } = useForm<ExceptionDate>({
    defaultValues: exceptionDate
      ? exceptionDate
      : {
          name: holidays.length > 0 ? holidays[0].name : '',
          date: holidays.length > 0 ? dayjs(holidays[0].date).format(DatePickerFormat) : undefined,
          isLunchOffer: true,
          preorderDeliveryPricing: deliveryPricing.Normal,
        },
  });

  const date = watch('date');

  useEffect(() => {
    if (!date || exceptionDate || holidays.length < 1) return;

    const holiday = holidays.find((d) => dayjs(date).isSame(d.date));

    if (holiday) {
      setValue('name', holiday.name);
    } else {
      setValue('name', '');
    }
  }, [date, holidays, setValue, exceptionDate]);

  useEffect(() => {
    if (holidays.length < 1) return;

    reset(
      exceptionDate
        ? exceptionDate
        : {
            name: holidays.length > 0 ? holidays[0].name : '',
            date: holidays.length > 0 ? dayjs(holidays[0].date).format(DatePickerFormat) : undefined,
            isLunchOffer: true,
            preorderDeliveryPricing: deliveryPricing.Normal,
          }
    );
  }, [holidays, reset, exceptionDate]);

  const handleSave: SubmitHandler<ExceptionDate> = async (payload) => {
    try {
      await mutation.mutateAsync(payload);
      snackBar.showSuccess('New exception date saved');
      queryClient.getQueryCache().clear();
      onDialogClose();
    } catch (e) {
      console.error(e);
      snackBar.showError('Could not save new exception date');
    }
  };

  if (isLoading) {
    return null;
  }

  return (
    <Dialog open={open} keepMounted maxWidth="sm" fullWidth>
      <DialogTitle>
        <Box display="flex">
          <Box alignItems="center" display="flex" flex="1">
            {exceptionDate ? t('exceptionDate.editExceptionDate') : t('exceptionDate.addExceptionDate')}
          </Box>
          <Box display="flex">
            <IconButton onClick={onDialogClose} aria-label="close" color="primary">
              <CloseOutlined />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box padding={2}>
          <DateTimePicker
            name="date"
            control={control}
            label={t('exceptionDate.list.date')}
            error={errors['date']}
            isDirty={isDirty}
            dateOnly
          />
        </Box>
        <Box padding={2}>
          <CustomTextFieldInput
            name="name"
            control={control}
            label={t('exceptionDate.list.localName')}
            error={errors['name']}
            isDirty={isDirty}
          />
        </Box>
        <Box padding={2}>
          <FormSwitch name="isLunchOffer" control={control} label={t('exceptionDate.list.isLunchOffer')} />
        </Box>
        <Box padding={2}>
          <FormControl component="fieldset">
            <Typography variant="body1">{t('exceptionDate.list.deliveryPricing')}</Typography>
            <Controller
              control={control}
              name="preorderDeliveryPricing"
              render={({ field: { onChange, value } }) => (
                <RadioGroup
                  value={value}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    onChange(event.target.value);
                  }}
                >
                  <FormControlLabel
                    value={deliveryPricing.Normal}
                    control={<Radio />}
                    label={t('exceptionDate.deliveryPricing.normal')}
                  />
                  <FormControlLabel
                    value={deliveryPricing.Low}
                    control={<Radio />}
                    label={t('exceptionDate.deliveryPricing.lowered')}
                  />
                </RadioGroup>
              )}
            />
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box display="flex" padding="1em">
          <LoadingButton
            onClick={handleSubmit(handleSave)}
            startIcon={<Save />}
            loading={mutation.isLoading}
            variant="contained"
            color="primary"
          >
            {t('global.save')}
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default EditExceptionDateDialog;
