import { useTranslation } from 'react-i18next';
import Switch from '@mui/material/Switch';
import {
  Button,
  FormControlLabel,
  Chip,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Typography,
  Link,
  Box,
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { useEffect, useMemo, useState } from 'react';
import { HasAllPermissions, PRODUCTS_WRITE } from '../../shared/utils/permissions';
import { useUserPermissions } from '../../apis/user-details';
import styled from 'styled-components';
import { dateTimeLocal } from '../../shared/utils/dateHelpers';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { ProductDetails } from './ProductDetails';
import { Search } from '@mui/icons-material';
import Fuse from 'fuse.js';
import StyledDataGrid from '../../shared/DataGrid/StyledDataGrid';
import { useAllProducts } from '../../apis/products-api';

export const ProductsPage = (): JSX.Element => {
  const { t } = useTranslation();
  const { roles: userPermissions } = useUserPermissions();
  const navigate = useNavigate();

  const [showNotActive, setShowNotActive] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const [copied, setCopied] = useState('');

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied('');
      }, 1000);
    }
  }, [copied]);

  const toggleNotActive = () => {
    setShowNotActive((prev) => !prev);
  };

  const { data: initialData, isLoading } = useAllProducts({
    select: (data) => {
      return data?.map((product) => {
        return {
          ...product,
          id: product.productId,
          updatedCombined: product.updatedBy + ' ' + dateTimeLocal(product.updated),
        };
      });
    },
  });

  const [filteredRows, setFilteredRows] = useState(initialData ?? []);
  const fuse = useMemo(
    () =>
      new Fuse(initialData || [], {
        threshold: 0.2,
        ignoreLocation: true,
        keys: ['name', 'productId', 'description', 'productCategoryName'],
      }),
    [initialData]
  );

  useEffect(() => {
    const activeData = showNotActive ? initialData : initialData?.filter((x) => x.active);

    if (searchValue.length >= 3) {
      fuse.setCollection(activeData || []);
      setFilteredRows([...fuse.search(searchValue).map((result) => result.item)]);
    }
    if (searchValue.length < 3) {
      setFilteredRows(activeData || []);
    }
  }, [fuse, initialData, searchValue, showNotActive, setFilteredRows]);

  const notActiveCount = (initialData ?? []).filter((product) => !product.active).length;

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'id',
      flex: 1,
      renderCell: (params) => {
        const handleClick = () => {
          navigator.clipboard.writeText(params.row.id);
          setCopied(params.row.id);
        };
        return <StyledLink onClick={handleClick}>{copied === params.row.id ? 'kopioitu' : params.id}</StyledLink>;
      },
    },
    {
      field: 'name',
      headerName: 'Tuote',
      renderCell: (params) => {
        const handleClick = () => {
          navigate('/products/' + params.id);
        };
        return <StyledLink onClick={handleClick}>{params.value}</StyledLink>;
      },
      width: 190,
      flex: 2,
    },
    {
      field: 'productCategoryName',
      headerName: 'Tuoteryhmä',
      width: 100,
      flex: 2,
    },
    { field: 'description', headerName: 'Kuvaus', width: 800, flex: 6 },
    {
      field: 'active',
      headerName: 'Tila',
      flex: 1,
      renderCell: (params) => {
        return (params.value as boolean) ? (
          <Chip color="primary" label={t('shared.activeLabel.active')} />
        ) : (
          <Chip color="error" label={t('shared.activeLabel.inactive')} />
        );
      },
    },
    { field: 'updatedCombined', headerName: t('product.updated'), width: 170, flex: 2 },
  ];

  const handleNewProduct = () => {
    navigate('/products/new');
  };

  return (
    <Routes>
      <Route path=":id" element={<ProductDetails />} />
      <Route
        index
        element={
          <>
            <TopBar>
              <Typography variant="h1">{t('product.title')}</Typography>
              <Button
                disabled={!HasAllPermissions(userPermissions || [], [PRODUCTS_WRITE])}
                variant="contained"
                onClick={handleNewProduct}
                color="primary"
              >
                {t('product.addProduct')}
              </Button>
            </TopBar>
            <StyledToolbar variant="outlined">
              <Grid display="flex" container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <SearchInput
                    id="search-products"
                    placeholder={t('global.search')}
                    variant="outlined"
                    value={searchValue}
                    autoComplete="off"
                    onChange={(e) => {
                      e.preventDefault();
                      setSearchValue(e.target.value);
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid display="flex" alignItems="center" item xs={12} sm={6}>
                  <FormControlLabel
                    control={<Switch size="small" checked={showNotActive} onChange={toggleNotActive} />}
                    label={`${t('product.showInactive')} (${notActiveCount} kpl)`}
                  />
                </Grid>
              </Grid>
            </StyledToolbar>

            <DataGridContainer display="flex">
              <StyledDataGrid
                disableRowSelectionOnClick
                rows={filteredRows}
                columns={columns}
                getRowId={(row) => row.productId}
                loading={isLoading}
                getRowClassName={(params) => (params.row.active ? '' : 'grid-row-not-active')}
              />
            </DataGridContainer>
          </>
        }
      />
    </Routes>
  );
};

export const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 15px 0;
`;

const StyledLink = styled(Link)`
  font-size: 15px;
`;

const StyledToolbar = styled(Paper)`
  margin-bottom: 1em;
  display: flex;
  padding: 1em;
`;

const SearchInput = styled(TextField)`
  margin-right: 2em;
  flex: 0 1 500px;
  width: 100%;
`;

const DataGridContainer = styled(Box)`
  height: calc(100vh - 300px);
`;

export default ProductsPage;
